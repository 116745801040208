import React, { Suspense } from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Success from "../components/Success"

const SucessPage = () => {
  return (
    <Layout>
      <SEO title="Sucess" image={socialImage} />
      <Success />
    </Layout>
  )
}

export default SucessPage

import React from "react"
import { Link } from "gatsby"

import "./styles.scss"

const Success = () => {
  return (
    <div className="success">
      <p className="success-text">Votre message a bien été envoyé.</p>
      <Link className="success-link" to="/">
        Retourner à l'accueil
      </Link>
    </div>
  )
}

export default Success
